import { Container, Card, CardHeader, CardFooter, CardTitle } from "reactstrap"

const Error = () => (
    <Container className="my-5" style={{maxWidth: 600}}>
        <Card>
            <CardHeader>
                <CardTitle className="mb-0"><i className="fas fa-info-circle text-warning " /> Document Unavailable</CardTitle>
            </CardHeader>

            <CardFooter>
                <p className="mb-0">This document is currently unavailable for signature or the link you are on has expired. You may close and leave this page at any time.</p>
            </CardFooter>
            
        </Card>
    </Container>
)

export default Error;