import { pdfjs } from 'react-pdf';

const renderUpload = async (pdfFile, onImgLoaded, onPagesFound, cb) => {
    // console.log(pdfFile)

    pdfjs.getDocument({data: pdfFile}).promise.then(async pdfDoc => {

        const totalPages = pdfDoc.numPages
        onPagesFound(totalPages)
        let pageNumber = 0
        let awaitCounter = 0

        // for each page from the pdf render it as a canvas
        while(pageNumber < parseInt(totalPages)) {
            pageNumber++
            await renderPage(pageNumber, totalPages, pdfDoc, onImgLoaded);
            awaitCounter++;
            if(awaitCounter === totalPages) {
                document.getElementById('canvas-anchor').innerHTML = '';
                cb(pdfDoc)
                pdfDoc.destroy()
            }

        }
       
    })
}


export const renderPreview = async (base64data, onImgLoaded, cb) => {

    const pdfDoc = await  pdfjs.getDocument({data: atob(base64data)})

    const totalPages = pdfDoc.numPages

    let pageNumber = 0
    let awaitCounter = 0

    // for each page from the pdf render it as a canvas
    while(pageNumber < parseInt(totalPages)) {
        pageNumber++
        await renderPage(pageNumber, totalPages, pdfDoc, onImgLoaded);
        awaitCounter++;
        if(awaitCounter === totalPages) cb(pdfDoc)

    }

}

const renderPage = (pageNumber, totalPages, pdfDoc, onImgLoaded) => new Promise (async resolve => {
    pdfDoc.getPage(pageNumber).then(async function(page) {

        const canvasID = `pdf-canvas-page-${pageNumber}`
        const container = document.getElementById('canvas-anchor')

        const {  multiplier, finalWidth, finalHeight } = getDimensions(page)

        // createPageNumber({container, pageNumber, totalPages })
        createPageCanvas({container, pageNumber, canvasID, finalWidth, finalHeight })

        var canvas = document.getElementById(canvasID);
        var ctx = canvas.getContext('2d');
        
        // var viewport = page.getViewport({scale: multiplier});
        var viewport = page.getViewport({scale: multiplier * 2});

        canvas.height = finalHeight * 2;
        canvas.width = finalWidth * 2;
        canvas.style.transform = 'scale(.5)';
        canvas.style.position = 'absolute';
        canvas.style.top = -finalHeight / 2 + 'px';
        canvas.style.left = -finalWidth / 2 + 'px';

        // Render PDF page into canvas context
        page.render({ canvasContext: ctx, viewport: viewport }).promise.then(() => {

            const dataURL = canvas.toDataURL("image/svg",1);
            onImgLoaded(dataURL, pageNumber - 1)

            resolve()
        });


    });

})


const getDimensions = (page) => {

    const checkViewport = page.getViewport({scale: 1});

    const currentWidth = checkViewport.width
    const currentHeight = checkViewport.height

    const desiredWidth = document.querySelector('.archk-template-docs').offsetWidth

    const multiplier = desiredWidth / currentWidth
    const finalWidth = currentWidth * multiplier
    const finalHeight = currentHeight * multiplier

    return { multiplier, finalWidth, finalHeight }

}

const createPageCanvas = (params) => {

    // 1 create a wrapper to store the canvas
    const wrapperID = params.canvasID + '-wrapper';

    var newWrapper = document.createElement('div')
    newWrapper.id = wrapperID;
    newWrapper.style.position = 'relative'

    newWrapper.setAttribute('data-pdf-page-wrapper', 'true')

    params.container.appendChild(newWrapper)

    const wrapperElement = document.getElementById(wrapperID)

    var newCanvas = document.createElement('canvas');

    newCanvas.id = params.canvasID;
    newCanvas.style.width = document.querySelector('.archk-template-docs').offsetWidth;
    newCanvas.style.height = 'auto';
  
    newCanvas.setAttribute('data-page', params.pageNumber)
    newCanvas.setAttribute('data-width', params.finalWidth)
    newCanvas.setAttribute('data-height', params.finalHeight)
    newCanvas.setAttribute('data-pdf-page', "true")



    wrapperElement.appendChild(newCanvas)

}

export default renderUpload;