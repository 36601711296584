import * as actionTypes from '../../actions';
import store from 'store';

export const setOnlineStatus = (bool) => {
    store.dispatch({ type: actionTypes.SET_ONLINE_STATUS, payload: bool, });
}

export const toggleStandardLoader = (boolean) => {

    if(boolean === true) {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            return 'You content is still loading, if you leave this page you may have to refresh your window and progress may not be saved.';
        };
        window.onpopstate = (event) => {
            event.preventDefault();
            return 'You content is still loading, if you leave this page you may have to refresh your window and progress may not be saved.';
        };
        // window.addEventListener('popstate', unsavedProgress);
    } else {
        window.onbeforeunload = null;
        window.onpopstate = null;

    }

    store.dispatch({ type: actionTypes.TOGGLE_STANDARD_LOADER, payload: boolean, });
}

export const toggleAlertBS = (error, text) => {
    store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_ALERT_BS, payload: { error, text } });
}
