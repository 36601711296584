import { Container, Card, CardHeader, CardFooter, CardTitle } from "reactstrap"

const IsSigned = () => (
    <Container className="my-5" style={{maxWidth: 600}}>
        <Card>
            <CardHeader>
                <CardTitle className="mb-0"><i className="fas fa-check text-success " /> Document Signed</CardTitle>
            </CardHeader>

            <CardFooter>
                <p className="mb-0">This document has already been signed, no further action is required.</p>
            </CardFooter>
            
        </Card>
    </Container>
)

export default IsSigned;