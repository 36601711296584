import * as actionTypes from '../../actions';

const initialState = {
    LOGO: ''
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_LOADED:
            return { ...state, ...action.payload }
    
        default: return state;

    }

}

export default reducer;
