import { Modal } from 'reactstrap';

const SignModalConfirm = ({showModal, toggleModal, onSign}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">All Set!</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModal}
                        >
                        <span aria-hidden={true}>×</span>
                    </button>
                    </div>

                    <div className="modal-body">
                        <p className="text-sm mb-0">You're document is ready to go, all that's left is to click the green button below to finish signing!</p>
                    </div>
                    
                    <div className="modal-body border-top">

                        <button onClick={onSign} className="btn btn-lg btn-success btn-block"><i className="fas fa-signature mr-2 " /> Sign My Document!</button>
                       
                    </div>
                    <div className="modal-body border-top">

                    <div className="text-center">
                        <i className="fas text-warning fa-arrow-up  " /> 
                        <i className="fas text-warning fa-arrow-up ml-4 " /> 
                        <i className="fas text-warning fa-arrow-up ml-4 " /> 
                        </div>

                    </div>
                </div>


        </Modal>
    )
}

export default SignModalConfirm
