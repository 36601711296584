import { Modal } from "reactstrap";

const SignModalDisclosures = ({showModal, toggleModalTerms}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModalTerms}
        size="lg"
    >
        <div className="modal-header">
            <h5 className="modal-title">E-Signing Disclosures</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModalTerms}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">

            <h2>CONSUMER DISCLOSURE</h2>

            <p>From time to time, IntuiDesk LLC (we, us or Company) may be required by law to provide to you certain written notices or disclosures. Described below are the terms and conditions for providing to you such notices and disclosures electronically through the IntuiDesk LLC. (IntuiDesk) electronic signing system. Please read the information below carefully and thoroughly, and if you can access this information electronically to your satisfaction and agree to these terms and conditions, please confirm your agreement by clicking the ‘I agree’ button presented on the confirmation page.</p>

            {/* <h2>Getting paper copies</h2> */}

            {/* <p>At any time, you may request from us a paper copy of any record provided or made available electronically to you by us. You will have the ability to download and print documents we send to you through the IntuiDesk system during and immediately after signing session and, if you elect to create a IntuiDesk signer account, you may access them for a limited period of time (usually 30 days) after such documents are first sent to you. After such time, if you wish for us to send you paper copies of any such documents from our office to you, you will be charged a $0.00 per-page fee. You may request delivery of such paper copies from us by following the procedure described below.</p> */}

            <h2>Withdrawing your consent</h2>

            <p>If you decide to receive notices and disclosures from us electronically, you may at any time change your mind and tell us that thereafter you want to receive required notices and disclosures only in paper format. How you must inform us of your decision to receive future notices and disclosure in paper format and withdraw your consent to receive notices and disclosures electronically is described below.</p>

            <h2>Consequences of changing your mind</h2>

            <p>If you elect to receive required notices and disclosures only in paper format, it will slow the speed at which you can complete certain steps in transactions for in delivering services to you because we will need first to send the required notices or disclosures to you in paper format, and then wait until we receive back from you your acknowledgment of your receipt of such paper notices or disclosures. To indicate that you are changing your mind, you must withdraw your consent by notifiying the company that sent you this document. This will indicate to the company that you have withdrawn your consent to receive required notices and disclosures electronically and you will no longer be able to use the IntuiDesk system to sign electronically documents.</p>

            <h2>All notices and disclosures will be sent to you electronically</h2>

            <p>Unless you tell us otherwise in accordance with the procedures described herein, we will provide electronically to you through the IntuiDesk system all required notices, disclosures, authorizations, acknowledgements, and other documents that are required to be provided or made available to you during the course of our relationship with you. To reduce the chance of you inadvertently not receiving any notice or disclosure, we prefer to provide all of the required notices and disclosures to you by the same method and to the same address that you have given us. Thus, you can receive all the disclosures and notices electronically. If you do not agree with this process, please let us know as described below. Please also see the paragraph immediately above that describes the consequences of your electing not to receive delivery of the notices and disclosures electronically from us.</p>

            <h2>How to get in contact:</h2>

            <p>To get in contact and let us know of your changes as to how we may contact you electronically, to request paper copies of certain information from us, and to withdraw your prior consent to receive notices and disclosures electronically as follows: Contact the company who sent you this document.</p>

            <h2>To advise IntuiDesk LLC of your new e-mail address</h2>

            <p>To let us know of a change in your e-mail address where we should send notices and disclosures electronically to you, you must send an email message to the company who sent you this document.</p>

            <h2>To request paper copies from IntuiDesk LLC</h2>

            <p>To request delivery from us of paper copies of the notices and disclosures previously provided by us to you electronically, you must send us an e-mail to the company who sent you this document to electronically sign and in the body of such request you must state your e-mail address, full name, US Postal address, and telephone number. The company who sent you this document may charge for such a service.</p>

            <h2>To withdraw your consent with IntuiDesk LLC</h2>

            <p>To inform us that you no longer want to receive future notices and disclosures in electronic format you may:</p>

            <ol>
                <li>decline to sign a document from and close or leave this page, or you may;</li>
                <li>send us an e-mail to the company who sent you this document and in the body of such request you must state your email, full name, US Postal Address, and telephone number. We do not need any other information from you to withdraw consent. The consequences of your withdrawing consent for online documents will be that transactions may take a longer time to process.</li>
            </ol>

            <h2>Required hardware and software</h2>

            <h4>Operating Systems:</h4>
            <p>Windows® 2000, Windows® XP, Windows Vista®; Mac OS® X</p>

            <h4>Browswers</h4>
            <p>Final release versions of Internet Explorer® 6.0 or above (Windows only); Mozilla Firefox 2.0 or above (Windows and Mac); Safari™ 3.0 or above (Mac only)</p>

            <h4>Screen Resolution</h4>
            <p>800 x 600 minimum</p>

            <h4>Security Settings:</h4>
            <p>Allow per session cookies</p>

            <p>** ** These minimum requirements are subject to change. If these requirements change, you will be asked to reaccept the disclosure. Pre-release (e.g. beta) versions of operating systems and browsers are not supported.</p>

            <h2>Acknowledging your access and consent to receive materials electronically</h2>

            <p>To confirm to us that you can access this information electronically, which will be similar to other electronic
            notices and disclosures that we will provide to you, please verify that you were able to read this electronic
            disclosure and that you also were able to print on paper or electronically save this page for your future
            reference and access or that you were able to e-mail this disclosure and consent to an address where you will
            be able to print on paper or save it for your future reference and access. Further, if you consent to receiving
            notices and disclosures exclusively in electronic format on the terms and conditions described above, please
            let us know by clicking the ‘I agree’ button on the confirmation page.
            By checking the ‘I agree’ box, I confirm that:
            I can access and read this Electronic CONSENT TO ELECTRONIC RECEIPT OF ELECTRONIC
            CONSUMER DISCLOSURES document; and
            I can print on paper the disclosure or save or send the disclosure to a place where I can print it, for
            future reference and access; and
            Until or unless I notify the company who sent you this document as described above, I consent to receive from exclusively
            through electronic means all notices, disclosures, authorizations, acknowledgements, and other
            documents that are required to be provided or made available to me by IntuiDesk LLC during the
            course of my relationship with you.</p>


        </div>

        <div className="modal-footer">
            <button onClick={toggleModalTerms} className="btn btn-success" >
                Close
            </button>
        </div>

    </Modal>
)

export default SignModalDisclosures;