import { Col, Row, Progress } from 'reactstrap';

const SignSignatureProgress = ({progress, loaded, toggleModalFinishField, onPreview}) => {

    let percent = !loaded ? 0 : parseInt((progress.finished / progress.total) * 100);
    if(Number.isNaN(percent)) percent = 0;

    return !loaded ? <></> : (
        <div className="doc-progress bg-white border-top z-depth-5">
            <Row>
                <div className="align-self-center col-auto" style={{width: 'calc(100% - 150px)'}}>
                    <span onClick={toggleModalFinishField} className="cursor-pointer">
                        <span style={{width: '85px', display: 'inline-block'}}>Progress:</span>
                        <span style={{width: 'calc(100% - 155px)', display: 'inline-block'}}>
                            <Progress className="mb-0" max="100" value={percent.toFixed(2)} color="success" />
                        </span>
                        
                        

                        <span style={{width: '50px', display: 'inline-block'}} className={progress.total === progress.finished ? 'text-success font-weight-bold ml-3' : ' ml-3'}>
                            {percent.toFixed(2)}%
                        </span>
                    </span>
                </div>

                <Col className="align-self-center text-right col-auto">
                    {progress.finished === 0 ? (
                        <button onClick={toggleModalFinishField} className="btn btn-success">Get Started </button>
                    ) : progress.finished < progress.total ? (
                        <button onClick={toggleModalFinishField} className="btn btn-success">Next Question </button>
                    ) : (
                        <span className="position-relative d-inline-block">
                            <i className="fas fa-arrow-down confirmation-arrow " />
                            <button onClick={onPreview} className="btn btn-success">Review </button>
                        </span>
                    )}
                </Col>
            </Row>
        </div>
    )

}

export default SignSignatureProgress
