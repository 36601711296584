import React, { useState } from "react";

const ModalToggler = (props) => {

    const [showModal, setShowModal] = useState(props.showModal ? props.showModal : false);

    const toggle = () => {
        setShowModal(!showModal);
        if(props.toggleModal) props.toggleModal(showModal);
    }

    return (
        <span>
            <span onClick={toggle}>
                {props.children}
            </span>
            <props.component 
                {...props}
                showModal={showModal}
                toggleModal={toggle}
            />
        </span>
    )
 
}

export default ModalToggler



/*
Documentation


USAGE

<ModalToggler component={SendText} >
    <button className="btn btn-success">Send Text</button>
</ModalToggler>

*/