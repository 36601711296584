import * as actionTypes from '../../actions';
import store from '../../index';
import _auth from '_functions/auth';

export const setViewingUser = async() => {
    return new Promise( async resolve => {

        const viewing_user = await _auth.viewing_user()
        
        if(viewing_user.success) {

            store.dispatch({ type: actionTypes.SET_VIEWING_USER, payload: { objects: viewing_user.data } });
            resolve({success: true, data: viewing_user.data});

        } else {

            resolve({success: false});

        }

    })

}