import { useEffect, useState, useCallback } from "react";

import { toggleAlertBS } from 'store/functions/system/system';

import { Container } from 'reactstrap';
import renderUpload from '../_functions/renderUpload';
import { getMarkerPositionOnPage, getMarkerName } from '../_functions/utils'

import Loading from './Loading'
import Progress from './Progress'

import ModalFinishField from '../Modals/FinishField'
import ModalAddSignature from '../Modals/AddSignature'
import ModalAddInitials from '../Modals/AddInitials'


const SignSignature = ({signingDocument, pdfData, allMarkers, onPreview, party_id, setShowAcceptDisclosures}) => {

    const [signature, setSignature] = useState({signature: null, width: 0, height: 0});
    const [initials, setInitials] = useState({initials: null, width: 0, height: 0});
    const [markers, setMarkers] = useState([]);
    const [progress, setProgress] = useState({total: 0, finished: 0});
    const [lastIndex, setLastIndex] = useState(0);
    const [markerIndex, setMarkerIndex] = useState(0);
    const [showModalAddSignature, setShowModalAddSignature] = useState(false);
    const [showModalAddInitials, setShowModalAddInitials] = useState(false);
    const [showModalFinishField, setShowModalFinishField] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [renderedPages, setRenderedPages] = useState(<div />);
    const [showMarkers, setShowMarkers] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    const toggleModalAddSignature = useCallback(() => {
        setShowModalAddSignature(!showModalAddSignature)
    }, [showModalAddSignature])

    const toggleModalAddInitials = useCallback(() => {
        setShowModalAddInitials(!showModalAddInitials)
    }, [showModalAddInitials])

    const getProgress = useCallback(() => {
        let finished = 0;
        let total = 0
        markers.forEach(marker => {
            if(marker.type !== 'date') total++;
            if(marker.type === 'text' && marker.answer && marker.answer.trim())  finished++;
            if(marker.type === 'signature' && signature.signature) finished++;
            if(marker.type === 'initial' && initials.initials)    finished++;

        })

        if(total === finished) {
            setShowModalFinishField(false);
            toggleAlertBS('Last Step!', `You're ready to sign this document. Click the green "Review" button on the bottom of your screen when you are ready to proceed!`);
        }

        return setProgress({ total, finished })
    }, [initials, markers, signature])

    const onChangeAnswer = useCallback((index, answer) => {
        const _markers = JSON.parse(JSON.stringify(markers));
        _markers[index].answer = answer;
        setMarkers(_markers);
    }, [markers])

    const onSetSignature = useCallback((signature, width, height) => {
        setSignature({ signature, width, height });
    }, [])

    const onSetInitials = useCallback((initials, width, height) => {
        setInitials({initials, width, height});
    }, [])

    const unFocusLastMarker = useCallback(() => {
        const lastSelectedMarker = document.querySelector(`[data-marker-index='${lastIndex}']`);
        lastSelectedMarker.classList.remove('is-focused')
    }, [lastIndex])

    const focusMarker = useCallback((_markerIndex) => {
        unFocusLastMarker();
        const selectedMarker = document.querySelector(`[data-marker-index='${_markerIndex}']`);
        if(selectedMarker) {
            selectedMarker.classList.add('is-focused')
            setLastIndex(_markerIndex)
        }
        window.scrollTo({top: getMarkerPositionOnPage(markers[_markerIndex]).top - 0, left: 0})
    }, [unFocusLastMarker, markers])

    const moveBackwards = useCallback(() => {
        let _markerIndex = markerIndex - 1;
        if(_markerIndex < 0) _markerIndex = markers.length - 1;

        focusMarker(_markerIndex)
        setMarkerIndex(_markerIndex)
    }, [focusMarker, markerIndex, markers.length])

    const moveForwards = useCallback(() => {
        let _markerIndex = markerIndex + 1;
        if(_markerIndex >= markers.length) _markerIndex = 0;

        focusMarker(_markerIndex)
        setMarkerIndex(_markerIndex)
        getProgress();
    }, [focusMarker, markerIndex, markers.length, getProgress])

    const toggleModalFinishField = useCallback((_markerIndex) => {
        if(showModalFinishField) {
            unFocusLastMarker()
        } else {
            focusMarker(_markerIndex)
        }
        const newIndex = _markerIndex !== undefined ? _markerIndex : markerIndex
        setShowModalFinishField(!showModalFinishField)
        setMarkerIndex(newIndex)
    }, [unFocusLastMarker, focusMarker, markerIndex, showModalFinishField])

    const getImgWidth = useCallback((image, _marker) => {
        const maxHeight = (_marker.bottom - _marker.top) * (_marker.pageHeight / _marker.pageHeight )
        const maxWidth = (_marker.right - _marker.left) * (_marker.pageWidth / _marker.pageWidth)

        const ratio = maxHeight / image.height

        let imgWidth = image.width * ratio

        if(imgWidth > maxWidth) {
            let ratio2 = imgWidth / maxWidth
            imgWidth = (imgWidth / ratio2)
        }

        return imgWidth;
    }, [])

  
    const createMarkers = useCallback(() => {

        if(loaded) return markers.map((marker, i) => {
            try {

                let finalWidth = 'auto';
                let finalHeight = 'auto';

                const positionStyles = getMarkerPositionOnPage(marker);

                const style = { position : 'absolute', ...positionStyles };
                const key = marker.top.toString() + marker.left.toString();

                let classNames = marker.answer || marker.type === 'date' || (marker.type === 'signature' && signature.signature) || (marker.type === 'initial' && initials.initials) ? 
                    'pdf-marker pdf-marker-filled z-depth-2' : 
                    'pdf-marker z-depth-2';

                if(marker.type === 'date') classNames += ' pdf-marker-date'

                if(i === markerIndex) classNames += ' is-focused'

                if(marker.type === 'signature') finalWidth = getImgWidth(signature, marker)
                if(marker.type === 'initial') finalWidth = getImgWidth(initials, marker)

                return  (
                    <span onClick={() => toggleModalFinishField(i)}  key={key} data-marker-index={i} className={classNames} style={style} >
                        <div style={{width: marker.right - marker.left, overflow: 'hidden', fontSize: positionStyles.fontSize}} className="marker-name">
                            {marker.type === 'signature' && signature.signature ? (
                                <img style={{width: finalWidth, height: finalHeight}} src={signature.signature} alt="signature" />
                            ) : marker.type === 'initial' && initials.initials ? (
                                <img style={{width: finalWidth, height: finalHeight}} src={initials.initials} alt="initials" />
                            ) : (
                                getMarkerName(marker)
                            )}
                        </div>
                    </span>
                )
            } catch(e) {
                console.log(e)
                return <div />
            }
        })

    }, [getImgWidth, markers, initials, loaded, markerIndex, signature, toggleModalFinishField])

    const showMarkersOnceRendered = useCallback((_totalPages) => {
        const checkRender = (tries = 0) => {
            if(tries > 100) return false
            let fullyRendered = false;

            // make sure each page is rendered and rendered in full in the dom
            for (let i = 1; i <= _totalPages; i++) {
                const el = document.querySelector(`[data-page="${i}"]`);
                if(el && el.offsetHeight) fullyRendered = true;
            }

            if(fullyRendered) {
                window.scrollTo(0,0)
                return setShowMarkers(true)
            } else {
                setTimeout(() => checkRender(tries + 1), 150)
            }
        }
        checkRender();
    }, [])

    const onResize = useCallback(() => {    
        setShowMarkers(false);
        setTimeout(() => setShowMarkers(true), 500)
    }, [])

    useEffect(() => {
        document.body.classList.add('noScroll')
        window.addEventListener('resize', onResize)

        const _markers = JSON.parse(JSON.stringify(allMarkers))
        _markers.sort((a, b) => a.top < b.top ? -1 : 1)
        _markers.sort((a, b) => a.pageNumber < b.pageNumber ? -1 : 1)

        setMarkers(_markers)
        let _images = []
        let _totalPages = 0

        const onImageLoaded = (img, page) => {
            _images[page] = img;
            setImagesLoaded(_images.length)
        }
        const onPagesFound = (pages) => {
            _totalPages = pages;
            setTotalPages(pages)
        }

        renderUpload(pdfData, onImageLoaded, onPagesFound, () => {
            setLoaded(true)
            document.body.classList.remove('noScroll')

            setShowAcceptDisclosures(true)
            const markup = [];

            _images.forEach((image, i) => {
                markup.push((
                    <div key={i}>
                        <p className="text-sm text-right mb-0">Page {i + 1} / {_totalPages}</p>
                        <div id={`pdf-canvas-page-${i + 1}-wrapper`} data-pdf-page-wrapper="true" style={{position: 'relative', width: '100%'}}>
                            <div className="page-overlay" />
                            <img id={`pdf-canvas-page-${i + 1}`} className="z-depth-3 mb-3 rounded" data-page={i + 1} src={image} alt={`page ${i + 1}`} style={{width: '100%'}} />
                        </div>
                    </div>
                ))
            })
            setRenderedPages(markup);
            showMarkersOnceRendered(_totalPages)
           
        })

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [allMarkers, pdfData, signingDocument.company.name, showMarkersOnceRendered, onResize, setShowAcceptDisclosures])

    return (

        <>
            
            <Container className="archk-template-docs-container pb-6">
                <div className="archk-template-docs"  >
                    <div className="archk-template-docs-main">
                        <div style={{height: 'auto',  maxWidth: '100%',  margin: 'auto'}} >

                            {!loaded && <Loading progress={(imagesLoaded / totalPages) * 100} /> }

                            <div id="canvas-wrapper" className="position-relative" style={{opacity: loaded ? 1 : 0}}>
                                <div id="pdf-canvas" className="position-relative">
                                    {loaded ? renderedPages : <></>}
                                    {showMarkers ? createMarkers() : null}
                                </div>
                            </div>

                        </div>
                    </div>

                    <Progress 
                        loaded={loaded} 
                        progress={progress} 
                        onPreview={() => onPreview({
                            signature: signature.signature,
                            initials: initials.initials,
                            markers
                        })} 
                        toggleModalFinishField={() => toggleModalFinishField(markerIndex >= 0 ? markerIndex : 0)}
                    />

                </div>
            </Container>

            <div id="canvas-anchor" style={{display: 'none'}} />

            <ModalFinishField
                moveForwards={moveForwards}
                moveBackwards={moveBackwards}
                markerIndex={markerIndex}
                markers={markers}
                onChangeAnswer={onChangeAnswer}
                showModalFinishField={showModalFinishField}
                signature={signature}
                initials={initials}
                toggleModalFinishField={() => toggleModalFinishField()}
                toggleModalAddSignature={toggleModalAddSignature}
                toggleModalAddInitials={toggleModalAddInitials}
            />

            {showModalAddSignature ? (
                <ModalAddSignature 
                    showModalAddSignature={showModalAddSignature}
                    toggleModalAddSignature={toggleModalAddSignature}
                    setSignature={onSetSignature}
                    signature={signature}
                    signingDocument={signingDocument}
                    party_id={party_id}
                />
            ) : null}

            {showModalAddInitials ? (
                <ModalAddInitials 
                    showModalAddInitials={showModalAddInitials}
                    toggleModalAddInitials={toggleModalAddInitials}
                    setInitials={onSetInitials}
                    initials={initials}
                    signingDocument={signingDocument}
                    party_id={party_id}
                />
            ) : null}

        </>

    );
}

export default SignSignature
