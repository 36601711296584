import { useCallback } from 'react';

import { Container, Card, CardHeader, CardFooter, CardTitle, CardBody } from "reactstrap"

import fileDownloader from 'js-file-download';

import api from 'api';

const Download = ({signedPDFData, signingDocument, party_id}) => {

    const onDownload = useCallback(() => {
        let name = signingDocument.name;
        if(name.slice(signingDocument.name.length - 4, signingDocument.name.length) !== '.pdf')  {
            name += '.pdf';
        }
        fileDownloader(signedPDFData, name) 
        api.signing_events.create({
            party_id             : party_id, 
            event               : 'Downloaded Signed Copy'
        })

    }, [signedPDFData, party_id, signingDocument.name])

    return (
        <Container className="my-5" style={{maxWidth: 600}}>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">{signingDocument.company.name}</CardTitle>
                </CardHeader>

                <CardBody>
                    <h4>Your document has been signed!</h4>
                    <p>A copy of this document will be emailed to you when all parties have finished signing it. You may also click the "download" button below to download a signed copy of your document to keep for your records.</p>
                    <p className="mb-0">Note that for security reasons, one you close or leave this page you will not be able to come back to view or sign your document.</p>
                </CardBody>
               
                <CardFooter className="text-right">
                    <button onClick={onDownload} className="btn btn-success"><i className="fas fa-download mr-2 " /> Download</button>
                </CardFooter>

            </Card>
        </Container>
    )
}

export default Download;