import { Modal } from 'reactstrap';

const SignModalHelp = ({showModal, toggleModal}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Help Desk</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <h4 className="text-dark mb-0">How do I get started signing my document?</h4>
            <p className="text-sm ">To get started signing your document click any <b className="font-weight-bold text-yellow">yellow marker</b> on your document OR click the <b className="font-weight-bold text-success">green button</b> in the bottom right corner of your screen.</p>
            <h4 className="text-dark mb-0">Can I review my document before I send it?</h4>
            <p className="text-sm ">Yes! After you fill out your document you will be taken to a screen where you can review your answers and signatures.</p>
            <h4 className="text-dark mb-0">Is this contract legally binding?</h4>
            <p className="text-sm ">E-signing a contract has the same legal bindings as signing a contract with via pen and paper.</p>
            <h4 className="text-dark mb-0">Can I make the contract bigger?</h4>
            <p className="text-sm ">Yes you can, either zoom in if you are on a phone OR view the page from a laptop / desktop.</p>

        </div>

        <div className="modal-footer">
            <button onClick={toggleModal} className="btn btn-outline-warning">Close</button>
        </div>

    </Modal>
)

export default SignModalHelp;