import * as actionTypes from '../../actions';
import store from 'store';

import _load from '_functions/load';

export const setLoaded = async() => {
    return new Promise( async resolve => {
        const data = await _load.standard()

        if(data.success) {
            store.dispatch({ type: actionTypes.SET_LOADED, payload: data.data });
            resolve({success: true, data: data.data});
        } else {
            resolve({success: false});
        }
    })

}