
import r from './request'

const api = {

    auth: {
        mfa: {
            send: (d)  => r('SERVER', 'get', `/v1/auth/mfa?_id=${d._id}&company=${d.company}&type=${d.type}`),   
            set: (d)   => r('SERVER', 'post', `/v1/auth/mfa/set`, d),   
        },
        password: {
            update: (d)  => r('SERVER', 'post', `/v1/auth/password/update`, d),
            reset:  (d)  => r('SERVER', 'post', `/v1/auth/password/reset`, d),
        },
        sso: {
            fetch:   ()   => r('SERVER', 'get', `/v1/auth/sso`),
            signOn:  (d)  => r('SERVER', 'post', `/v1/auth/sso`, d),
        },
        viewing_user: ()    => r('SERVER', 'get', `/v1/auth/viewing_user`),
    },

    signing_documents: {
        findById : (i, a)   => r('SERVER', 'get',  `/v1/signing/documents/${i}/`),
        password : (i, d)   => r('SERVER', 'post',  `/v1/signing/documents/${i}/password`, d),
        sign : (i, d)       => r('SERVER', 'post',  `/v1/signing/documents/${i}/sign`, d),
        preview : (i, d)       => r('SERVER', 'post',  `/v1/signing/documents/${i}/preview`, d),
    },
    
    signing_events: {
        create : (d)   => r('SERVER', 'post',  `/v1/signing/events`, d),
    },

    documents: {
        download : (d) => r('SERVER', 'get', `/api/documents/${d.document}/download/${d.company}/${d.password}`),
    },
    companies: {
        findByName    : (name)          =>  r('SERVER', 'get', `/v1/api/companies/name/${name}`, null),
        findById      : (company_id)    =>  r('SERVER', 'get', `/v1/api/companies/${company_id}`, null),
        register      : (data)          =>  r('SERVER', 'post', `/v1/api/companies/register`, data, true),    
    },
  
   
}

export default api;