import { Container, Card, CardHeader, CardFooter, CardTitle } from "reactstrap"

const NewLinkSent = () => (
    <Container className="my-5" style={{maxWidth: 600}}>
        <Card>
            <CardHeader>
                <CardTitle className="mb-0"><i className="fas fa-info-circle text-info " /> New Document Link</CardTitle>
            </CardHeader>

            <CardFooter>
                <p className="mb-0">To protect the security of this document a new link was sent to either the phone number or email address on file. Please use that link to access this document.</p>
            </CardFooter>
            
        </Card>
    </Container>
)

export default NewLinkSent;