/*
Documentation

this function needs to run when the app loads
when it is finished it returns true if all went well or false if it did not

*/

import { setLoaded } from 'store/functions/load/load';
import { setViewingUser } from 'store/functions/auth/auth';
import { setOnlineStatus } from 'store/functions/system/system';
import stackLogger from 'database/stackLogger';
import redirectToApp from 'utils/redirectToApp'
import { initWorker } from 'utils/pdf/controller'

const init = () => new Promise (async resolve => {

    // prevent accidental dropping of files into the system
    window.addEventListener("dragover",function(e){ e.preventDefault(); },false);
    window.addEventListener("drop",function(e){ e.preventDefault(); },false);

    // gets access to internet, not fired on load
    window.addEventListener('online', () => setOnlineStatus(true));
    window.addEventListener('offline', () => setOnlineStatus(false));

    let errors = 0;

    const tryLoad = async (tries = 0) => {

        errors = 0;

        //run all promises that need to fire before the app loads here
        const values = await Promise.all([
            setViewingUser(),
            setLoaded(),
        ])

        // make sure all required calls pass
        values.forEach(v => { if(v.success === false) { errors++; } });

        // if we dont have an error resolve success
        if(!errors) {
            initWorker();
            return resolve(true);
        }
        // if we have tried to load over 3 times resolve error
        if(tries > 2) return resolve(false);

        setTimeout(() => tryLoad(tries + 1), 2000);

    }

    tryLoad();


    window.onerror = function(msg, file, line, col, e) {
        stackLogger(e);
    };

})

export default init