import { useState, useCallback, memo, useEffect } from 'react';

import { Container, Card, CardHeader, CardFooter, CardTitle, CardBody, FormGroup, Input } from "reactstrap"

const Password = ({signingDocument, onPasswordSucceeded, validatePassword}) => {

    const [err, setErr] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    
    const onPasswordSubmitted = useCallback(async (password) => {
        if(!password) return setErr('Please enter your document password.');
        setLoading(true)
        validatePassword(signingDocument, password, (err, data) => {
            setLoading(false)
            if(err) return setErr(err);
            onPasswordSucceeded(data);
        })
    }, [signingDocument, onPasswordSucceeded, validatePassword]);

	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') {
			document.getElementById("archk-template-docs-confirm-password").click();
		}
	}, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container className="my-5" style={{maxWidth: 600}}>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Security Check</CardTitle>
                </CardHeader>
                {err === 'Invalid Password, Attempts Remaining: 0' ? (
                    <CardBody>
                        <p className="text-sm mb-0"><i className="fas fa-info-circle text-info " /> This document has reached the maximum amount of password attempts. Please contact the company that sent you this document.</p>
                    </CardBody>
                ) : (
                    <>
                        <CardHeader className="bg-secondary">
                            <p className="mb-0">Before this document can be signed a password must be entered. Please enter the information below before proceeding</p>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <label className="form-control-label">{signingDocument.contactParty.hint}</label>
                                <Input 
                                    onKeyDown={_handleKeyDown}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormGroup>
                        </CardBody>
                    </>
                )}

                {err ? (
                    <CardFooter>
                        <p className="text-sm text-warning font-weight-bold mb-0">{err}</p>
                    </CardFooter>
                ) : null}

                <CardFooter className="text-right">
                    <button 
                        id="archk-template-docs-confirm-password" 
                        onClick={() => onPasswordSubmitted(password)} 
                        className="btn btn-success"
                        disabled={loading}
                    >
                        Submit
                    </button>
                </CardFooter>
            </Card>
        </Container>
       
    )

}

export default memo(Password);