import { Card, CardBody, Row, Col } from 'reactstrap'
import ModalHelp from './Modals/Help'

import ModalToggler from 'components/functional/modals/Toggler'

const Header = ({signingDocument}) => (
    <Card className="bg-secondary archk-template-docs-header" style={{marginBottom: 5}}>
        <CardBody>
            <Row>
                <div className="col-auto align-self-center" style={{width: 'calc(100%) - 50px'}}>
                    {signingDocument.name.length > 35 ? signingDocument.name.slice(0, 35) + '...' : signingDocument.name}
                </div>
                <Col className="text-right align-self-center">
                    <ModalToggler component={ModalHelp}>
                        <i style={{position: 'relative', top: 2}} className="fas fa-info-circle cursor-pointer text-info-original " />
                    </ModalToggler>
                </Col>
            </Row>
        </CardBody>
    </Card>
)

export default Header;