
import Sign from '../pages/Sign';
import FORM_REMOVE_AFTER_8_30 from '../pages/FORM_REMOVE_AFTER_8_30';

const routes = [


    {
        path: "/ident/submission",
        component: FORM_REMOVE_AFTER_8_30,
    },
    
    {
        path: "/:party_id",
        component: Sign,
    },
  
    {
        path: "/",
        component: Sign,
    },
  
]

export default routes;