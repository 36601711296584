import * as actionTypes from '../../actions';

const initialState = {
    alertBS: null,
    standardLoader: false,
    loader: false,
    online: true,   
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.TOGGLE_STANDARD_LOADER:
            return { ...state, standardLoader: action.payload }

        case actionTypes.SET_ONLINE_STATUS:
            return { ...state, online: action.payload }

        case actionTypes.SET_SETTINGS:
            return { ...state, SETTINGS: action.payload }

        case actionTypes.TOGGLE_SYSTEM_ALERT_BS:
            if(action.payload && action.payload.error !== undefined && action.payload.text) {
                return {
                    ...state,
                    alertBS: {
                        error: action.payload.error,
                        text: action.payload.text,
                    }
                }
            } else {
                return { ...state, alertBS: null }
            }

        default: return state;

    }

}

export default reducer;
