import { Container, FormGroup, Input, Card, CardFooter, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { useState, useCallback, useEffect } from 'react';

import Axios from 'axios';

const FormRemoveAfter830 = () => {

    const [err, setErr] = useState('')
    
    const [accessCode, setAccessCode] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [businessTitle, setBusinessTitle] = useState('')
    const [companyEIN, setCompanyEIN] = useState('')

    const onSubmit = useCallback(async () => {
        setErr(null)

        if(!name) return setErr('Name must be entered.')
        if(!phone) return setErr('phone must be entered.')
        if(!email) return setErr('Email must be entered.')
        if(!companyName) return setErr('Company Name must be entered.')

            try {

                const submitted = await Axios({
                    // url: `http://localhost:5001/v1/custom_endpoint/ident_visa_mc`,
                    method: 'POST',
                    url: `https://api.intuidesk.com/v1/custom_endpoint/ident_visa_mc`,
                    headers: {
                        "ident-custom-key": 'ofSyMQeFbkaNd81iadsqXITgj7i96tPvbqdf'
                    },
                    data: {
                        name,
                        email,
                        phone,
                        access_code: accessCode,
                        company_name: companyName,
                        business_title: businessTitle,
                        company_ein: companyEIN,
                    }

                })

                if(submitted.data && submitted.data.success) {

                    setAccessCode('')
                    setName('')
                    setPhone('')
                    setEmail('')
                    setCompanyName('')
                    setBusinessTitle('')
                    setCompanyEIN('')

                    return alert('Submission Successfully Received')
                } else {
                    setErr('Could not complete your submission at this time, please try again. Code 1')
                }

            } catch(e) {
                console.log(e)
                setErr(`Could not complete your submission at this time, please try again. Code 2`)
            }

    }, [accessCode, businessTitle, companyEIN, companyName, email, name, phone])

    return (
        <Container className='py-3 '>
            <Card className='card-no-shadow z-depth-1' style={{maxWidth: 500, margin: 'auto'}}>
                
                <CardHeader>
                    <CardTitle className='mb-0'>iDent Visa/MC FormSubmission</CardTitle>
                </CardHeader>
              
                <CardHeader>
                    <FormGroup>
                        <label className='form-control-label text-warning'>***ACCESS CODE</label>
                        <Input 
                            type="text"
                            value={accessCode}
                            onChange={e => setAccessCode(e.target.value)}
                        />
                    </FormGroup>

                </CardHeader>
                
                <CardBody>

                    <FormGroup>
                        <label className='form-control-label'>***Contact Name</label>
                        <Input 
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormGroup>


                    <FormGroup>
                        <label className='form-control-label'>***Email Address</label>
                        <Input 
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className='form-control-label'>***Phone #</label>
                        <Input 
                            type="text"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                    </FormGroup>
                   
                    <FormGroup>
                        <label className='form-control-label'>***Company Name</label>
                        <Input 
                            type="text"
                            value={companyName}
                            onChange={e => setCompanyName(e.target.value)}
                        />
                    </FormGroup>
                    
                    <FormGroup>
                        <label className='form-control-label'>Business Title (Of The Contact)</label>
                        <Input 
                            type="text"
                            value={businessTitle}
                            onChange={e => setBusinessTitle(e.target.value)}
                        />
                    </FormGroup>
                    
                    <FormGroup>
                        <label className='form-control-label'>Company EIN</label>
                        <Input 
                            type="text"
                            value={companyEIN}
                            onChange={e => setCompanyEIN(e.target.value)}
                        />
                    </FormGroup>


                </CardBody>

                {err ? (
                    <CardFooter>
                        <p className='text-sm text-warning mb-0 font-weight-bold'><i className="fas fa-exclamation-triangle" /> {err}</p>
                    </CardFooter>
                ) : ''}

                <CardFooter>
                    <button onClick={onSubmit} className='btn btn-success'>Submit Form</button>
                </CardFooter>

            </Card>
        </Container>
    )
}

export default FormRemoveAfter830;