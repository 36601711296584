import React from 'react';
import moment from 'moment';

export const getMarkerPositionOnPage = (marker) => {

    const container = document.getElementById('pdf-canvas')

    if(!container) return null

    const originalWidth = marker.pageWidth;
    const originalHeight = marker.pageHeight;
    
    const currentWidth =  document.querySelector('.archk-template-docs').offsetWidth;
    const currentHeight =  document.querySelector(`[data-page="${marker.pageNumber}"]`).offsetHeight;

    const ratioWidth = currentWidth / originalWidth
    const ratioHeight = currentHeight / originalHeight

    const containerPosition = container.getBoundingClientRect()
    const containerTop = containerPosition.top + window.scrollY


    const page = document.getElementById(`pdf-canvas-page-${marker.pageNumber}`)
    if(!page) return null;

    const pagePosition = page.getBoundingClientRect()
    const pageTop = pagePosition.top + window.scrollY

    const offsetFromTop = pageTop - containerTop

    return {
        top         : (marker.top * ratioHeight) + offsetFromTop,
        left        : marker.left * ratioWidth,
        width       : (marker.right - marker.left) * ratioWidth,
        height      : ((marker.bottom - marker.top)) * ratioHeight,
        fontSize    : 12 * ratioWidth    
    }

   
}

export const getMarkerIcon = (marker) => {

    if(!marker.type) return

    return marker.type === 'text' ? (
        <i className={`fas fa-text-width party-${marker.party}`}  />
    ) : marker.type === 'date' ? (
        <i className={`fas fa-calendar party-${marker.party}`} />
    ) : marker.type === 'initial' ? (
        <i className={`fas fa-signature party-${marker.party}`} />
    ) : (
        <i className={`fas fa-file-signature party-${marker.party}`} />
    )
}

export const getMarkerName = (marker) => {

    if(!marker.type) return

    return marker.type === 'text' ? (
        marker.answer ? marker.answer : marker.name
    ) : marker.type === 'date' ? (
        moment().format('MM/DD/YYYY')
    ) : marker.type === 'initial' ? (
        'INITIAL'
    ) : (
        'SIGNATURE'
    )
}

