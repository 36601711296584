import React, { useCallback } from 'react';
import { useState } from 'react';
import { Modal } from 'reactstrap';

import api from 'api'

const AcceptDisclosures = ({showModal, toggleModal, toggleModalTerms, party_id}) => {

    const onAccept = useCallback(() => {
        api.signing_events.create({
            party_id             : party_id, 
            event               : 'Confirmed Consent To ESign'
        })
        toggleModal()
    }, [party_id, toggleModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => {}}
            size="md"
        >
    
            <div>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Let's Get Started!</h5>
                </div>
    
                <div className="modal-body">
    
                    <p className="text-sm mb-0">
                        I consent to executing this agreement electronically in accordance with{' '}
                        <span onClick={toggleModalTerms} className="cursor-pointer text-underline text-dark font-weight-bold">UETA Consent Disclosures</span>
                    </p>
    
                </div>
    
                <div className="modal-footer">
                    <button onClick={onAccept} className="btn btn-outline-success">I Agree</button>
                </div>
            </div>
    
        </Modal>
    
    )
};



export default AcceptDisclosures
